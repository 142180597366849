<template>
  <article-page :menu="menu">
    <div class="training-content" id="section-mark-as-read-type">
      <div class="_subtitle">
        Просмотр тендера
      </div>
      <span>
        После сохранения настроек поиска в шаблон, рядом с названием шаблона появляется <b>количество непросмотренных лотов</b>.
      </span>
      <article-image :src="images[0].src" :alt="images[0].alt" @click="openViewer(images, 0)"/>
      <span>
        При поиске по сохраненному шаблону, лоты, помеченные тегом "Новая закупка" и выделенные оранжевым цветом фона, являются <b>непросмотренными</b>.
      </span>
      <span>
        При поиске по шаблону применяется сортировка - непросмотренные лоты находятся сверху списка результатов, а просмотренные - в конце.
      </span>
      <article-image :src="images[1].src" :alt="images[1].alt" @click="openViewer(images, 1)"/>
      <span>
        По умолчанию лоты отмечаются просмотренными при нахождении названия тендера в зоне видимости на экране более 2 секунд.
        При необходимости, в
        <accessed-link :name="Tabs.Settings.InterfaceSettings" target="_blank"> настройках функциональности </accessed-link>
        эту опцию можно поменять.
      </span>
      <article-image :src="images[2].src" :alt="images[2].alt" width="600px" @click="openViewer(images, 2)"/>
      <span>
        При выборе второго значения, лоты отмечаются просмотренными при взаимодействии с карточкой.
        Сюда входят смена статуса, ответственного, написание комментария, взаимодействия с кнопками, а так же любой клик в пределах карточки лота.
      </span>
      <span>
        При выборе третьего значения, лоты отмечаются просмотренными при клике на кнопку "Отметить лот просмотренным" (цифра 1 на картинке ниже).
      </span>
      <article-image :src="images[3].src" :alt="images[3].alt" @click="openViewer(images, 3)"/>
    </div>
    <div class="training-content" id="auto-mark-as-read">
      <div class="_subtitle">
        Автоматическая отметка лотов просмотренными
      </div>
      <span>
        В <accessed-link :name="Tabs.Settings.SystemManagement" target="_blank"> системных настройках </accessed-link> указано количество дней,
        через которое непросмотренный тендер отмечается просмотренным. Это сделано для того, чтобы неактуальные тендеры не накапливались в больших количествах.
        Первоначальная настройка - 3 календарных дня. Вы можете указать любое количество дней в пределах от 1 дня до 7.
      </span>
    </div>
  </article-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useImageViewer } from "@/use/other/useImageViewer";
import ArticlePage from "@/components/pages/training/ArticlePage.vue";
import ArticleImage from "@/components/pages/training/ArticleImage.vue";
import AccessedLink from "@/components/ui/links/AccessedLink.vue";
import { Tabs } from "@/router/tabs";
import AlertMessage from "@/components/ui/alert/AlertMessage.vue";

export default defineComponent({
  name: "WatchedLotsArticle",
  components: {
    AlertMessage,
    AccessedLink,
    ArticleImage,
    ArticlePage,
  },
  setup() {

    const menu = [
      { id: 'section-mark-as-read-type', title: 'Настройка отметки лотов' },
      { id: 'auto-mark-as-read', title: 'Автоматическая отметка лотов просмотренными' },
    ]

    const images = [
      { src: 'lots/template-selector.png', alt: 'Кол-во непросмотренных лотов' },
      { src: 'lots/unseen-lots.png', alt: 'Непросмотренный и просмотренный лот' },
      { src: 'lots/unseen-settings.png', alt: 'Настройки' },
      { src: 'lots/unseen-button.png', alt: 'Кнопка "Отметить лот просмотренным"' },
    ]

    const { openViewer } = useImageViewer();

    return {
      menu,
      images,
      openViewer,
      Tabs,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/pages/training';
</style>
